import { GlobalPage } from '@codecademy/brand';
import { AppWrapper } from '@codecademy/gamut';
import React, { ComponentProps } from 'react';
import { useSelector } from 'react-redux';

import { useAppHeaderProps } from '~/components/AppHeader/useAppHeaderProps';
import { useBrandedBanner } from '~/components/BrandedBanner/useBrandedBanner';
import { PageMeta, PageMetaProps } from '~/components/PageMeta';
import { ccdata } from '~/libs/ccdata';
import { isChromeOSPWA } from '~/libs/device';
import { trackUserClick } from '~/libs/eventTracking';
import { selectCurrentUserCountry } from '~/state/currentUser/selectors';
import { useFeatureFlag } from '~/state/features/hooks';

export interface CorporateContainerProps {
  pageMetaProps?: PageMetaProps;
  showBanner?: boolean;
}

export const CorporateContainer: React.FC<
  React.PropsWithChildren<CorporateContainerProps>
> = ({ showBanner, ...props }) =>
  showBanner ? (
    <CorporateContainerWithBanner {...props} />
  ) : (
    <GlobalPageInner {...props} />
  );

const CorporateContainerWithBanner: React.FC<
  React.PropsWithChildren<CorporateContainerProps>
> = (props) => {
  const banner = useBrandedBanner();

  return <GlobalPageInner {...props} banner={banner} />;
};

const GlobalPageInner: React.FC<
  React.PropsWithChildren<
    CorporateContainerProps & {
      banner?: ComponentProps<typeof GlobalPage>['banner'];
    }
  >
> = ({ children, pageMetaProps, banner }) => {
  const headerProps = useAppHeaderProps();
  const locale = useSelector(selectCurrentUserCountry);
  const isReferralRockEnabled = useFeatureFlag('referral_rock_v1');

  return (
    <GlobalPage
      backgroundColor="beige"
      banner={banner}
      footer={{
        hidePricing: isChromeOSPWA(),
        onClick: ({ target }) => {
          trackUserClick({ context: 'global_footer', target });
        },
        userGeo: locale,
        referralRockUrls:
          (isReferralRockEnabled && {
            stats: ccdata.get('referralrock_stats_url') ?? '',
            tos: ccdata.get('referralrock_tos_url') ?? '',
          }) ||
          undefined,
      }}
      header={headerProps}
    >
      {pageMetaProps && <PageMeta {...pageMetaProps} />}
      <AppWrapper as="main">{children}</AppWrapper>
    </GlobalPage>
  );
};
