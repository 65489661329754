import { Anchor, AnchorProps } from '@codecademy/gamut';
import React from 'react';

export interface LinkWithRedirectProps {
  href: string;
  redirectUrl?: string;
  variant?: AnchorProps['variant'];
}

export const LinkWithRedirect: React.FC<
  React.PropsWithChildren<LinkWithRedirectProps>
> = ({ children, href, redirectUrl, variant }) => {
  const url = redirectUrl
    ? `${href}?redirect=${encodeURIComponent(redirectUrl)}`
    : href;
  return (
    <Anchor href={url} variant={variant}>
      {children}
    </Anchor>
  );
};
