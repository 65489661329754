import { Box, ContentContainer, Text } from '@codecademy/gamut';
import React from 'react';

export interface CorporateFormPageProps {
  title: string;
}

export const CorporateFormPage: React.FC<
  React.PropsWithChildren<CorporateFormPageProps>
> = ({ children, title }) => {
  return (
    <ContentContainer size="wide">
      <Box mx="auto" my={64} width="22rem" maxWidth="calc(100% - 2rem)">
        {title && (
          <Text as="h1" variant="title-md" pb={16} lineHeight="base">
            {title}
          </Text>
        )}
        {children}
      </Box>
    </ContentContainer>
  );
};
